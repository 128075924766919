.docTypePosition-desktop {
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  min-width: calc(100% - 50px);
  background-color: transparent;
  padding: 0px 0px;
  text-align: right;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.doctypebutton {
  color: black;
}

// *** Mobile ***
.docTypePosition-mobile {
  position: fixed;
  bottom: 0px;
  z-index: 1000;    
  min-width: 100%;
  background-color: transparent;
  margin: 0px 0px 55px 0px;
  text-align: right;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}