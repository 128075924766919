.libraryDetail {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    min-height: 80vh;
    max-height: 80vh;
    width: 440px;
    float: left;
    font-size: .9em;
}

.topdiv, .bottomdiv {
    display: block;
    padding: 5px;
    position: sticky;
    height: 30px;
    bottom: 0px !important;
}

.labelText {
    display: block;
    width: 100%;
    min-height: 20px;
}

.k-grid-content {
    overflow-x: hidden !important;
}

.gridFolders {
    max-height: 70vh !important;
    overflow-y: auto;
    overflow-x: hidden !important;
    cursor: pointer;
}

.smallCount{
    font-size: 0.65em;
}
 
.k-grid .k-table-row {
    background-color: whitesmoke   !important; // Light gray
}

.k-grid .k-table-row.k-table-alt-row {
    background-color: whitesmoke !important; // Dark gray
}

.k-grid th, .k-grid td {
    text-overflow: clip !important;
    white-space: wrap !important;
}

.totaldiv {
    padding: 3px;
}