html, body {
  width: 100%;
  height: 100%;
}

body,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
    overscroll-behavior-y: contain;
    overflow: hidden;
}

a {
    text-decoration: none;
}

.left {
    float: left;
}

.right {
    float: right;
}

.center {
    text-align: center;
    width: 100%;
}

.loader {
    background-color: whitesmoke;
    border-radius: 7px;
    padding: 10px 20px;
    vertical-align: middle;
    text-align: center;
    width: 40px; 
    height: 40px;
    position: fixed;
    top: 50%;
    left: 45%;
    z-Index: 9999;
}

.loader-mobile {
    background-color: whitesmoke;
    border-radius: 7px;
    padding: 10px 20px;
    vertical-align: middle;
    text-align: center;
    width: 40px; 
    height: 40px;
    position: fixed;
    top: 50%;
    left: 40%;
    z-Index: 9999;
}

.textleft {
  text-align: left !important;
}

.textright {
    text-align: right !important;
}

.k-window {
  opacity: 0.95;
}

.k-window-title {
  cursor: move !important;
}

.k-window-titlebar {
  padding: 2px 8px;
  background-color: #e6e6e6;
  background-image: linear-gradient(#fafafa, #e6e6e6);
  color:black;
  font-size: 1em;
}

.k-window-content {
  overflow: hidden;
  padding: 3px;
  background-color: #f0f0f0;
}

.image-container {
  display: inline-block;
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.image-container:hover .tooltip {
  display: block;
}

.button {
  color:white !important;
  background-color: #b51e26ce !important;
  margin-left: 2px;
}

.hidden {
  display: none;
}
