.header {
    background-color: #e0e0e0;
}

.header-desktop {
    height: 66px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    background-position: left;
    background-repeat: no-repeat;
}

.header-docmulti {
    height: 66px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    background-position: left;
    background-repeat: no-repeat;
}

.headerleft {
    float: left;
    width: 25%;
    z-index: 1000;
    margin: 5px;
    vertical-align: center;
    color: #9F0102;
}

.headermiddle {
    text-align: center;
    min-width: 900px;
    z-index: 2000;
}

.headerright {
    float: right;
    text-align: right;
    padding: 3px;
    width: 15%;
    z-index: 1000;
}

.navigationbox {
    position: relative;
    text-align: center;
    margin-top: 10px;
    padding: 4px 10px;
    height: 30px;
    min-width: 700px;
    border-radius: 8px;
    background-color: whitesmoke;
}

.docprologo {
    position:fixed;
    display: block;
    top:0px !important;
    right:5px !important;
    width: 150px;
    height: 45px;    
    z-index: 99999;
    background-repeat: no-repeat !important;
    background-position: right;
    background-image: url('../../public/images/docpro.png');
    cursor: pointer;
}

.foldercurrent {
    display: flex;
    align-items: center;
    h3 {
        margin-left: 8px;
    }
}

.foldercurrent::after {
    content: attr(data-content);
    display: block;
    margin: 0 auto;
    padding: 3px 3px 0px 3px;
    width: 85%;
    text-align: center;    
    height: 20px;
    font-size: 0.86em;
    font-weight: bold;
    background-color: white;
    border-radius: 10px 10px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.matchboxdiv {
    float: left;
    margin-left: 10px;
}

.pagenavigationboxdiv {
    float: right;
}

.pagesdiv-docmulti, .librariesdiv-docmulti, .matchsdiv-docmulti, .pageslabel-docmulti, .librarieslabel-docmulti, .matchslabel-docmulti {
  width: 100px;
  display: block;
  text-align: center;
  font-weight: 700;
  float:left;
  font-size: 0.82em; 
}

.pagestotal-docmulti, .librariestotal-docmulti, .matchstotal-docmulti {
  width: 100px;
  display: block;
  text-align: center;
  font-size: 1em;
  color: rgb(83, 82, 82);
  float:left; 
}

.documentedgediv {
  left: 0px;
  top: 90px;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.documentedgecontentdiv {
  height: 5px;
  width: 100%;
  background-image: linear-gradient(#A0A0A0,transparent);
}

.userloggeddiv {
  position: absolute;
  right: 0px;
  font-size: small;
  color: white;
  padding: 1px 5px;;
  background-color: #9F0102 ;
  top: 68px;
  border-radius: 5px;
}

.logoutdiv {
  text-decoration: underline;
  cursor: pointer;
}

// *** Mobile ***
.header-mobile {
    align-items: center;
    display: flex;
    height: 66px !important;
}

.header-docmulti-mobile {
    align-items: center;
    display: flex;
    height: 66px !important;
    background-color: #e0e0e0;
}

.navigationbox-mobile {
    position: relative;
    width: max-content;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 5px;
    left: 40px
}
    
.searchicon-mobile {
    margin-left: auto;
    vertical-align: middle;
    width: 32px;
    filter: invert(100%); // https://codepen.io/sosuke/pen/Pjoqqp
}

.headerleft-mobile {
    float: left;
    left: -10px;
    width: 30px;
}

.headermiddle-mobile {
    text-align: center;
    width: 100%;
}

.headerright-mobile {
    float: right;
    text-align: right;
    padding: 10px;
    width: 30px;
}

.matchsdiv-mobile {
    background-color: whitesmoke;
    border-radius: 8px;
    font-size: small;
    padding: 3px;
    margin: 60px;
}